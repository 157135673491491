import React, { useState, useEffect, useMemo, useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "gatsby";
import { slugify } from "utils/index";
import _ from "lodash";
import { StaticImage } from "gatsby-plugin-image";

const OTHER_COUNTRY = {
  fr_fr: "Autres Pays",
  en_gb: "Other countries",
  es_es: "Otros países",
  de_de: "Andere Länder",
};

const ABOUT_US = {
  fr_fr: "Que proposons-nous?",
  en_gb: "What do we offer?",
  es_es: "¿Qué ofrecemos?",
  de_de: "Was schlagen wir vor?",
};

export const Header = ({ content, staticTexts, notFound, lang }) => {
  const getCountryMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _countries = content.countries;
    const _otherCountries = [...content.otherCountries];
    const indexOtherCountry = _otherCountries.findIndex(
      (c) => _.camelCase(c.menuTitle) === _.camelCase(OTHER_COUNTRY[lang])
    );

    if (indexOtherCountry !== -1) {
      _otherCountries.splice(indexOtherCountry, 1);
    }

    return [..._countries, ..._otherCountries]
      .sort((a, b) => a.menuTitle.localeCompare(b.menuTitle))
      .concat(
        indexOtherCountry !== -1
          ? [content.otherCountries[indexOtherCountry]]
          : []
      )
      .map((country) => {
        return {
          link: `/${slugify(
            `${staticTexts.componentCountriesSlug}${
              country.pronoun ? country.pronoun + "-" : ""
            }${country.slug}`
          )}`,
          text: country.menuTitle,
          subMenu: [],
        };
      });
    //eslint-disable-next-line
  }, []);

  const getOtherPages = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _others = content.others;
    const _contactPage = {
      menuTitle: staticTexts.templateNousContacterH1,
      slug: slugify(staticTexts.templateNousContacterH1),
      text: staticTexts.templateNousContacterH1,
    };

    return [..._others, _contactPage]
      .filter((page) => page.menuTitle !== ABOUT_US[lang])
      .map((page) => ({
        link: `/${page.slug}`,
        text: page.menuTitle,
        subMenu: [],
      }));
    //eslint-disable-next-line
  }, []);

  const getCompanySub = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _companyMenus = content.companyMenus;

    return _companyMenus
      .sort((a, b) => a.order - b.order)
      .filter((menu) => {
        const companies = content.companies.filter((company) => {
          return (
            company.secondaryMenu[_.camelCase(lang)] === menu[_.camelCase(lang)]
          );
        });

        return companies.length;
      })
      .map((menu) => {
        const companies = content.companies
          .filter(
            (company) =>
              company.secondaryMenu[_.camelCase(lang)] ===
              menu[_.camelCase(lang)]
          )
          .map((company) => ({
            link: `/${slugify(
              `${staticTexts.componentHeaderSlugPart1}${
                company.pronoun ? company.pronoun + "-" : ""
              }${company.slug}${staticTexts.componentHeaderSlugPart2}`
            )}`,
            text: company.menuTitle,
            subMenu: [],
          }));

        return {
          link: "/",
          text: menu[_.camelCase(lang)],
          subMenu: companies,
        };
      });
    //eslint-disable-next-line
  }, []);

  const getBlogSub = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _blogs = content.blogs;

    return _blogs
      .sort((a, b) => a.menuTitle.localeCompare(b.menuTitle))
      .map((blog) => {
        return {
          link: `/${slugify(blog.slug)}`,
          text: blog.menuTitle,
          subMenu: [],
        };
      });
    //eslint-disable-next-line
  }, []);

  const getSubMenu = useMemo(() => {
    return {
      Pays: getCountryMenus,
      Aide: getOtherPages,
      Destination: getCompanySub,
      Blog: getBlogSub,
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Get top_menu from Contentful
   */
  const getTopMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _topMenus = content.topMenus;
    const _others = content.others;
    const _links = {};
    const _subMenus = getSubMenu;

    const aboutUs = _topMenus.find(
      (menu) => menu.frFr === "Que proposons-nous?"
    ).frFr;

    const aboutUsPage = _others.find(
      (other) => other.topMenu.fields && other.topMenu.fields.frFr === aboutUs
    );
    _links["Que proposons-nous?"] = "/" + (aboutUsPage ? aboutUsPage.slug : "");

    const newTopMenus = _topMenus
      .sort((a, b) => a.order - b.order)
      .map((menu) => {
        return {
          text: menu[_.camelCase(lang)],
          subMenu: _subMenus[menu.frFr] || [],
          link: _links[menu.frFr] || "/",
        };
      });

    return newTopMenus;
    //eslint-disable-next-line
  }, []);

  const [topMenus] = useState(getTopMenus);

  return (
    <header className="header header-wrapper hide-mobile">
      <Link to={`/`} className="header-logo">
        {!notFound ? (
          <LazyLoadImage
            src={staticTexts.logoWhite.file.url}
            alt={staticTexts.websiteLogoAltText}
            width={120}
            height={60}
          />
        ) : null}
      </Link>
      <nav>
        <ul className="header-text">
          {topMenus.map((menu, index) => (
            <li key={index}>
              <Link to={menu.link} className="header-link">
                {menu.text}
              </Link>

              {menu.subMenu.length ? (
                <ul>
                  {menu.subMenu.map((subMenu, index) => (
                    <li key={index}>
                      <Link to={subMenu.link}>{subMenu.text}</Link>

                      {subMenu.subMenu.length ? (
                        <ul>
                          {subMenu.subMenu.map((company, index) => (
                            <li key={index} style={{ left: "140px" }}>
                              <Link to={company.link}>{company.text}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export const BurgerMenu = ({ content, staticTexts, notFound, lang }) => {
  const getCountryMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _countries = content.countries;
    const _otherCountries = [...content.otherCountries];
    const indexOtherCountry = _otherCountries.findIndex(
      (c) => _.camelCase(c.menuTitle) === _.camelCase(OTHER_COUNTRY[lang])
    );

    if (indexOtherCountry !== -1) {
      _otherCountries.splice(indexOtherCountry, 1);
    }

    return [..._countries, ..._otherCountries]
      .sort((a, b) => a.menuTitle.localeCompare(b.menuTitle))
      .concat(
        indexOtherCountry !== -1
          ? [content.otherCountries[indexOtherCountry]]
          : []
      )
      .map((country) => {
        return {
          link: `/${slugify(
            `${staticTexts.componentCountriesSlug}${
              country.pronoun ? country.pronoun + "-" : ""
            }${country.slug}`
          )}`,
          text: country.menuTitle,
          nextMenu: "",
        };
      });
    //eslint-disable-next-line
  }, []);

  const getOtherMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _others = content.others;
    const _contactPage = {
      menuTitle: staticTexts.templateNousContacterH1,
      slug: slugify(staticTexts.templateNousContacterH1),
      text: staticTexts.templateNousContacterH1,
    };

    return [..._others, _contactPage]
      .filter((page) => page.menuTitle !== ABOUT_US[lang])
      .map((page) => ({
        link: `/${page.slug}`,
        text: page.menuTitle,
        nextMenu: "",
      }));
    //eslint-disable-next-line
  }, []);

  const getCompanyMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _companyMenus = content.companyMenus;

    return _companyMenus
      .sort((a, b) => a.order - b.order)
      .filter((menu) => {
        const companies = content.companies.filter((company) => {
          return (
            company.secondaryMenu[_.camelCase(lang)] === menu[_.camelCase(lang)]
          );
        });

        return companies.length;
      })
      .map((menu) => {
        // return {
        //   link: "",
        //   text: menu.frFr,
        //   nextMenu: menu.frFr,
        // };

        return {
          link: "/",
          text: menu[_.camelCase(lang)],
          nextMenu: menu[_.camelCase(lang)],
        };
      });
    //eslint-disable-next-line
  }, []);

  const getBlogMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _blogs = content.blogs;

    return _blogs
      .sort((a, b) => a.menuTitle.localeCompare(b.menuTitle))
      .map((blog) => {
        return {
          link: `/${slugify(blog.slug)}`,
          text: blog.menuTitle,
          nextMenu: "",
        };
      });
    //eslint-disable-next-line
  }, []);

  const getCompanies = useCallback((nextMenu) => {
    if (notFound) {
      return [];
    }

    return content.companies
      .filter((company) => company.secondaryMenu.frFr === nextMenu)
      .map((company) => ({
        link: `/${staticTexts.componentHeaderSlugPart1}${
          company.pronoun ? company.pronoun + "-" : ""
        }${company.slug}${staticTexts.componentHeaderSlugPart2}`,
        text: company.menuTitle,
        nextMenu: "",
      }));
    //eslint-disable-next-line
  }, []);

  /**
   * Get top_menu from Contentful
   */
  const getTopMenus = useMemo(() => {
    if (notFound) {
      return [];
    }

    const _topMenus = content.topMenus;
    const _others = content.others;
    const _links = {};

    const aboutUs = _topMenus.find(
      (menu) => menu.frFr === "Que proposons-nous?"
    ).frFr;
    const aboutUsPage = _others.find(
      (other) => other.topMenu.fields && other.topMenu.fields.frFr === aboutUs
    );

    _links["Que proposons-nous?"] = "/" + (aboutUsPage ? aboutUsPage.slug : "");
    _links["Accueil"] = "/";

    const newTopMenus = _topMenus
      .sort((a, b) => a.order - b.order)
      .map((menu) => {
        return {
          nextMenu: menu.frFr,
          text: menu[_.camelCase(lang)],
          link: _links[menu.frFr] || "",
        };
      });

    return newTopMenus;
    //eslint-disable-next-line
  }, []);

  const [topMenus] = useState(getTopMenus);
  const [countryMenus] = useState(getCountryMenus);
  const [companyMenus] = useState(getCompanyMenus);
  const [otherMenus] = useState(getOtherMenus);
  const [blogMenus] = useState(getBlogMenus);
  const [companies, setCompanies] = useState([]);
  const [menu, setMenu] = useState(false);
  const [nextMenu, setNextMenu] = useState("main");

  useEffect(() => {
    setCompanies(getCompanies(nextMenu));
    //eslint-disable-next-line
  }, [nextMenu]);

  return (
    <>
      <div
        className="burger-menu hide-desktop"
        style={{ display: menu === false ? "block" : "none" }}
      >
        <header className="burger-menu-closed">
          <Link to={`/`} className="burger-logo">
            {!notFound ? (
              <LazyLoadImage
                src={staticTexts.logoWhite.file.url}
                alt={staticTexts.websiteLogoAltText}
                width={120}
                height={60}
              />
            ) : null}
          </Link>{" "}
          <div className="burger-menu-bars">
            <StaticImage
              className="lazyload"
              src="../assets/icons/burger-menu-open.svg"
              alt="..."
              onClick={() => setMenu(true)}
              aria-hidden="true"
              placeholder="blur"
            />
          </div>
        </header>
      </div>
      <header
        className="burger-menu"
        style={{ display: menu === true ? "block" : "none" }}
      >
        <div className="burger-menu-open">
          <div className="burger-top">
            <Link to={`/`} className="burger-logo">
              {!notFound ? (
                <LazyLoadImage
                  src={staticTexts.logoColor.file.url}
                  alt={staticTexts.websiteLogoAltText}
                  width={120}
                  height={60}
                />
              ) : null}
            </Link>
            <div className="burger-menu-x">
              <StaticImage
                className="lazyload"
                src="../assets/icons/burger-menu-close.svg"
                alt="..."
                onClick={() => setMenu(false)}
                aria-hidden="true"
                placeholder="blur"
              />
            </div>
          </div>

          <nav className="burger-menu-links">
            <ul
              className="header-text"
              style={{ display: nextMenu === "main" ? "block" : "none" }}
            >
              {topMenus.map((menu, index) => (
                <li key={index}>
                  {menu.link.length ? (
                    <Link to={menu.link} className="header-link">
                      {menu.text}
                    </Link>
                  ) : (
                    <p
                      className="header-link"
                      onClick={() => setNextMenu(menu.nextMenu)}
                      onKeyDown={() => setNextMenu(menu.nextMenu)}
                      aria-hidden="true"
                    >
                      {menu.text}
                    </p>
                  )}
                </li>
              ))}
            </ul>

            <ul
              className="header-text"
              style={{ display: nextMenu === "Pays" ? "block" : "none" }}
            >
              <li onClick={() => setNextMenu("main")} aria-hidden="true">
                <p className="back-link">Back</p>
              </li>

              {countryMenus.map((menu, index) => (
                <li key={index}>
                  <Link to={menu.link}>{menu.text}</Link>
                </li>
              ))}
            </ul>

            <ul
              className="header-text"
              style={{
                display: nextMenu === "Destination" ? "block" : "none",
              }}
            >
              <li onClick={() => setNextMenu("main")} aria-hidden="true">
                <p className="back-link">Back</p>
              </li>

              {companyMenus.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => setNextMenu(menu.nextMenu)}
                  aria-hidden="true"
                >
                  <p className="header-link">{menu.text}</p>
                </li>
              ))}
            </ul>

            <ul
              className="header-text"
              style={{ display: companies.length ? "block" : "none" }}
            >
              <li onClick={() => setNextMenu("Destination")} aria-hidden="true">
                <p className="back-link">Back</p>
              </li>

              {companies.map((company, index) => {
                return (
                  <li key={index}>
                    <Link to={company.link}>{company.text}</Link>
                  </li>
                );
              })}
            </ul>

            <ul
              className="header-text"
              style={{ display: nextMenu === "Blog" ? "block" : "none" }}
            >
              <li onClick={() => setNextMenu("main")} aria-hidden="true">
                <p className="back-link">Back</p>
              </li>

              {blogMenus.map((menu, index) => (
                <li key={index}>
                  <Link to={menu.link}>{menu.text}</Link>
                </li>
              ))}
            </ul>

            <ul style={{ display: nextMenu === "Aide" ? "block" : "none" }}>
              <li onClick={() => setNextMenu("main")} aria-hidden="true">
                <p className="back-link">Back</p>
              </li>

              {otherMenus.map((menu, index) => {
                return (
                  <li key={index}>
                    <Link to={menu.link} className="header-link">
                      {menu.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};
