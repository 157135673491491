import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { BurgerMenu, Header } from "./Headers";
import { DesktopFooter, MobileFooter } from "./Footers";
import CookieConsent from "react-cookie-consent";

const Layout = ({
  menuContent,
  menuSection,
  menuTitle,
  slug,
  ratingValue,
  ratingCount,
  staticTexts,
  lang,
  notFound,
  company,
  ...components
}) => {
  const { bcJsonLD, orgJsonLD, reviewJsonTD, faqJSONLTD } = useMemo(() => {
    let bcJsonLD = null;
    let orgJsonLD = null;
    let reviewJsonTD = null;
    let faqJSONLTD = null;

    if (!notFound) {
      bcJsonLD = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": staticTexts.websiteUrl,
              name: "Losali Direct",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": staticTexts.websiteUrl + slug,
              name: menuTitle,
            },
          },
        ],
      };

      orgJsonLD = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: menuTitle,
        potentialAction: {
          "@type": "ViewAction",
          target: [
            staticTexts.websiteUrl + slug,
            {
              "@type": "EntryPoint",
              urlTemplate: staticTexts.websiteUrl + slug,
              contentType: "application/json+ld",
            },
          ],
        },
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: ratingValue,
          ratingCount: ratingCount,
        },
        contactPoint: [
          {
            "@type": "ContactPoint",
            url: `${staticTexts.websiteUrl}/appeler-les-08-et-les-numeros-surtaxes-depuis-la-suisse`,
            telephone: "+41900180078",
            contactType: "technical support",
            areaServed: "CH",
            availableLanguage: "French",
          },
          {
            "@type": "ContactPoint",
            url: `${staticTexts.websiteUrl}/appeler-les-08-et-les-numeros-surtaxes-depuis-l-espagne`,
            telephone: "+34807403330",
            contactType: "technical support",
            areaServed: "ES",
            availableLanguage: "French",
          },
          {
            "@type": "ContactPoint",
            url: `${staticTexts.websiteUrl}/appeler-les-08-et-les-numeros-surtaxes-depuis-la-belgique`,
            telephone: "+3290488020",
            contactType: "technical support",
            areaServed: "BE",
            availableLanguage: "French",
          },
          {
            "@type": "ContactPoint",
            url: `${staticTexts.websiteUrl}/appeler-les-08-et-les-numeros-surtaxes-depuis-le-royaume-uni`,
            telephone: "+449116130011",
            contactType: "technical support",
            areaServed: "GB",
            availableLanguage: "French",
          },
          {
            "@type": "ContactPoint",
            url: `${staticTexts.websiteUrl}/appeler-les-08-et-les-numeros-surtaxes-depuis-le-luxembourg`,
            telephone: "+35290048001",
            contactType: "technical support",
            areaServed: "LU",
            availableLanguage: "French",
          },
          {
            "@type": "ContactPoint",
            url: "https://losali.com",
            contactType: "technical support",
            areaServed: [
              "DZ",
              "DE",
              "BG",
              "CA",
              "CN",
              "US",
              "IL",
              "IT",
              "MA",
              "NL",
              "PT",
              "RE",
              "RO",
            ],
            availableLanguage: "French",
          },
        ],
      };

      if (company) {
        reviewJsonTD = {
          "@context": "https://schema.org/",
          "@type": "Organization",
          name: company.menuTitle,
          review: {
            "@type": "Review",
            reviewRating: {
              "@type": "Rating",
              ratingValue: ratingValue,
            },
            author: {
              "@type": "Organization",
              name: staticTexts.websiteUrl,
            },
            reviewBody: company.h1,
          },
        };

        let answer = company.metaDescription;

        if (typeof document !== "undefined") {
          const ele = document.createElement("div");
          ele.innerHTML = company.metaDescription;
          answer = ele.innerText;
        }

        faqJSONLTD = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: [
            {
              "@type": "Question",
              name: company.pageTitle,
              acceptedAnswer: {
                "@type": "Answer",
                text: answer,
              },
            },
            ...company.faqsSchema.map((faq) => {
              if (typeof document === "object") {
                const div = document.createElement("div");
                const newFaq = { ...faq };

                div.innerHTML = faq.acceptedAnswer.text;
                newFaq.acceptedAnswer.text = div.innerText.replace(/\n/g, " ");

                return newFaq;
              } else {
                return faq;
              }
            }),
          ],
        };
      }
    }

    return {
      bcJsonLD,
      orgJsonLD,
      reviewJsonTD,
      faqJSONLTD,
    };
  }, [notFound, company]);

  if (notFound) {
    return (
      <>
        <Header notFound={true} lang={lang} />
        <BurgerMenu notFound={true} lang={lang} />
        <div className="layout-wrapper" {...components} />
        <MobileFooter notFound={true} />
        <DesktopFooter notFound={true} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={staticTexts.favicon.file.url} />
        {/* declare language */}
        <html lang={lang.substr(0, 2)} />

        {menuTitle && (
          <script type="application/ld+json">{JSON.stringify(bcJsonLD)}</script>
        )}
        {menuTitle && (
          <script type="application/ld+json">
            {JSON.stringify(orgJsonLD)}
          </script>
        )}
        {menuTitle && reviewJsonTD && (
          <script type="application/ld+json">
            {JSON.stringify(reviewJsonTD)}
          </script>
        )}
        {menuTitle && faqJSONLTD && (
          <script type="application/ld+json">
            {JSON.stringify(faqJSONLTD)}
          </script>
        )}
      </Helmet>

      <Header content={menuContent} staticTexts={staticTexts} lang={lang} />
      <BurgerMenu content={menuContent} staticTexts={staticTexts} lang={lang} />

      <div className="layout-wrapper" {...components} />

      <MobileFooter staticTexts={staticTexts} />
      <DesktopFooter staticTexts={staticTexts} />

      <CookieConsent
        cookieName="gatsby-gdpr-google-analytics"
        buttonText={staticTexts.cookieConsentAccept}
        enableDeclineButton
        declineButtonText={staticTexts.cookieConsentRefuse}
        buttonClasses="losali-btn cookie-btn cookie-btn-accept"
        declineButtonClasses="losali-btn cookie-btn cookie-btn-decline"
        contentStyle={{ overflow: "hidden" }}
      >
        {staticTexts.cookieConsentIntro}
        <span style={{ fontSize: "10px" }}>
          {staticTexts.cookieConsentDetail}
        </span>
      </CookieConsent>
    </>
  );
};

export default Layout;
